@import "styles/easings.scss";
@import "styles/variables.scss";

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  caret-color: transparent;

  &:focus {
    outline: auto 5px -webkit-focus-ring-color;
  }
}

body {
  font-family: Roboto, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  color: var(--text);
  box-sizing: border-box;
  background-color: var(--background-light);
  scrollbar-width: thin;
  scrollbar-color: hsl(0 0% 50%);

  &.fixed {
    position: absolute;
    overflow: hidden;
    padding-right: 1rem; // simulate scroll padding
    @media only screen and (max-width: 1140px) {
      padding-right: 0;
    }
  }

  section,
  header,
  footer {
    &.minimized {
      transform: scale(0.8);
      opacity: 0.5;
    }
  }

  a {
    position: relative;
    text-decoration: none;
    font-weight: bold;
    color: var(--cta);
    transition: all 400ms cubic-bezier(0.25, 1, 0.5, 1);
  }

  @media (hover: hover) and (pointer: fine) {
    a:hover,
    a:active {
      color: var(--cta-light);
    }
  }

  a:before {
    content: "";
    position: absolute;
    bottom: -3px;
    right: 0;
    width: 0;
    height: 2px;
    background-color: var(--cta);
    transition: all 400ms cubic-bezier(0.25, 1, 0.5, 1);
  }

  a:after {
    content: "";
    position: absolute;
    bottom: -6px;
    right: 0;
    width: 8px;
    height: 8px;
    border: 2px solid transparent;
    border-left: none;
    border-top: none;
    transform: rotate(-45deg);
    transition: border-color 400ms 200ms cubic-bezier(0.25, 1, 0.5, 1);
  }

  a {
    color: var(--cta);
  }

  p {
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }

  h1,
  h2 {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }

  h1 {
    color: var(--color-light);
    font-family: Kanit, Arial, sans-serif;
    font-weight: 700;
    line-height: 100%;
  }

  h2 {
    color: var(--color);
    font-family: Montserrat, serif;
  }

  input {
    text-transform: lowercase;
    font-family: Roboto, sans-serif;
    font-size: 1rem;
  }

  #root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-display: swap;
}
