:root {
  // colors
  --background-light: #f8f8ff;
  --background-dark: #e9ebf5;
  --background-light-opacity: #f8f8ff90;
  --text: #021210;
  --color: #042e27;
  --color-light: #364966;
  --cta: #cc3d4b;
  --cta-light: #ea4454;
  --shadow: rgba(0, 8, 16, 0.3);
  --shadow-dark: rgba(0, 8, 16, 0.5);

  // border-radius
  --radius-xl: 48px;
  --radius-top: 48px 48px 0 0;
  --radius-lg: 16px;
  --radius-md: 8px;
  --radius-sm: 4px;
}
